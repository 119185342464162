@font-face {
	font-family: "poly";
	src: url("Poly-Regular.woff2") format('woff2');
}

@font-face {
	font-family: "poly";
	src: url("Poly-Regular.woff2") format('woff2');
	font-style: italic;
}
  
body { 
	font-family : poly; 
}

.gallery-image-wrapper img, p img {
	border:10px solid #ddd;
}

.gallery-image-wrapper {
	height: 300px;
	width: 300px;
	display: inline-block;
	margin: 1em;
	position: relative;
	text-align: center;
}
.gallery-image {
	margin: auto;
	position: absolute;
	top: 0px;
	bottom: 0px;
	max-height: 100%;
	max-width: 100%;
}
div.carousel {
	border: 5px solid white;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
	max-width:640px;
}
a.carousel-control {
	line-height: 24px;
}

.news {	
	margin-left:0;
	max-width: 640px;
	.page-header {
		margin-top: 0;
		background-color: #EBFFDE;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);//, inset 0 1px 0 rgba(255, 255, 255, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.3);
		
		-webkit-border-bottom-right-radius: 5px;
		-webkit-border-bottom-left-radius: 5px;
		-moz-border-radius-bottomright: 5px;
		-moz-border-radius-bottomleft: 5px;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
		.news-item {
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;
		}
		h1 {
			margin: 30px 0;
			margin-left: -10px;
			margin-right: -10px;
			margin-top:0px;
			padding: 20px 20px 24px;
			padding-left: 50px;
			border-radius: 0 5px 5px 0;
			position: relative;
			box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.3);
			
			background: #c9de96;
			background: -moz-linear-gradient(top, #8ab66b 0%, #398235 100%);
			background: -webkit-gradient(linear, top, bottom, color-stop(0%,#8ab66b), color-stop(100%,#398235));
			background: -webkit-linear-gradient(top, #8ab66b 0%,#398235 100%);
			background: -o-linear-gradient(top, #8ab66b 0%,#398235 100%);
			background: -ms-linear-gradient(top, #8ab66b 0%,#398235 100%);
			background: linear-gradient(to bottom, #8ab66b 0%,#398235 100%);
			
			color: white;
			
			&:before {
				content: "";
				position: absolute;
				bottom: -10px;
				left: 0px;
				border-color: #222 #222 transparent transparent;
				border-style: solid;
				border-width: 5px;
				width: 0;
				height: 0;
			}
			&:after {
				content: "✿";
				color: #fc0;
				position: absolute;
				top: 20px;
				left: 15px;
				font-size: 32px;
				text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);}
		}
	}
}